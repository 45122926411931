@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .tooltip {
    @apply rounded-md px-3 py-2 font-sans font-semibold text-white text-xs !important;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .resize-none {
    resize: none;
  }

  .container-height {
    max-height: 550px;
  }
  .container-sm-height{
    max-height: 570px;
  }

  .question-container-height {
    /* max-height: 570px; */
    min-height:570px;
  }

  .fade {
    position: absolute;
    bottom: 0px;

    display: block;

    width: 100%;
    height: 50px;

    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.9) 100%
    );
  }

  .scale-item {
    transform: scale(1.5);
  }

  .custom-max-h {
    max-height: 352px;
  }

  .dark-blue {
    background-color: #150d63;
  }

  .slide-in {
    animation: 1s cubic-bezier(0, 1, 0.5, 1) slideIn;
  }

  .fade-in {
    animation: 0.2s ease-in-out fadeIn;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}


/* Toggle A */
.toggle:checked ~ .dot {
  transform: translateX(100%);
  background-color: #1D4ED8 !important;
}

.ModalStyle1 {
  background-color: #fff;
  border: 1px solid #ccc;
  position: fixed;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 500px;
  margin: 8% auto;
  left: 0;
  right: 0;
  max-height: 80vh;
  overflow-y: auto;
}

@media screen and (max-width: 600px) {
  .ModalStyle1 {
    width: 345px;
  }
}

@media screen and (max-width: 400px) {
  .ModalStyle1 {
    width: 294px;
  }
}


@media screen and (max-width: 316px) {
  .ModalStyle1 {
    width: 252px;
  }
}

.ck-editor__editable_inline {
  min-height: 200px !important;
}


.danger{
  color: red;
}

span[color="danger"]{
  color: red;
}




::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  /* display: none; */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background:  #2977ff; /* #888; Color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background:  #2977ff; /* #555;  Color of the scrollbar thumb on hover */
}
/* Hide the number input spin buttons */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* margin: 0; */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}
